import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './ProfileOverlay.styles';
import { DropdownMenu } from '@app/components/header/Header.styles';

export const ProfileOverlay: React.FC = ({ ...props }) => {
  const navigation = useNavigate();

  const logout = () => {
    localStorage.removeItem('user');
    navigation('/auth/login');
  };

  return (
    <DropdownMenu selectable={false} {...props}>
      <S.MenuItem key={1} onClick={logout}>
        <S.Text>Déconnexion</S.Text>
      </S.MenuItem>
    </DropdownMenu>
  );
};
