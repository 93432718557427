import { DoctorApi } from '@app/api/doctors.api';
import { AppointmentType, DoctorType } from '@app/api/type';
import { renderImage } from '@app/services/utils/image';
import {
  Avatar,
  Button,
  Divider,
  Input,
  List,
  Modal,
  notification,
  Tag,
} from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';

const { Search } = Input;

type IProps = {
  appointment: AppointmentType | undefined;
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
};

const ModalRecommandation: React.FC<IProps> = ({
  appointment,
  isVisible,
  setIsVisible,
}) => {
  const [timer, setTimer] = useState<any>();
  const [list, setLists] = useState<DoctorType[]>([]);

  const onSearch = (event: any) => {
    clearInterval(timer);
    const value = event.target.value;
    if (value.length < 1) return setLists([]);
    const time = setTimeout(async () => {
      const res = await DoctorApi.search(value);
      setLists(res);
    }, 500);
    setTimer(time);
  };

  const closeModal = () => setIsVisible(false);

  const sendRecommandation = (doctorId: number) => {
    DoctorApi.sendRecommandation({
      doctorId,
      appointmentId: appointment?.id || 0,
    });
    closeModal();
    notification.success({ message: 'Recommandation envoyé avec succès' });
  };

  return (
    <Modal
      open={isVisible}
      title="Recommander un autre médecin"
      onCancel={closeModal}
    >
      <Search
        placeholder="Chercher"
        onChange={onSearch}
        style={{ width: '100%' }}
      />
      <List
        itemLayout="horizontal"
        dataSource={list}
        renderItem={(item: DoctorType) => (
          <List.Item
            actions={[
              <Button key="send" onClick={() => sendRecommandation(item.id)}>
                Envoyer
              </Button>,
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar src={renderImage(item.photoProfil)} />}
              title={item.name}
              description={
                <>
                  <small>{item.description}</small>
                  <Divider style={{ margin: 5 }} />
                  <div>
                    {item.speciality.map((speciality, i) => (
                      <Tag key={i}>{speciality.name}</Tag>
                    ))}
                  </div>
                </>
              }
            />
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default ModalRecommandation;
