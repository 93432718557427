import {
  createAction,
  createSlice,
  PayloadAction,
  PrepareAction,
} from '@reduxjs/toolkit';
import { persistUser, readUser } from '@app/services/localStorage.service';
import { UserLogin } from '@app/api/auth.api';
import { Availability } from '@app/components/profile/profileCard/profileFormNav/nav/notifications/Disponibilities/type';

export interface UserState {
  user: UserLogin | null;
  disponibilites: Availability[];
}

const initialState: UserState = {
  user: readUser(),
  disponibilites: [],
};

export const setUser = createAction<PrepareAction<UserLogin>>(
  'user/setUser',
  (newUser) => {
    persistUser(newUser);

    return {
      payload: newUser,
    };
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setDisponibilites: (state, action: PayloadAction<Availability[]>) => {
      state.disponibilites = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload;
    });
  },
});

export const { setDisponibilites } = userSlice.actions;

export default userSlice.reducer;
