import { PushpinOutlined, UploadOutlined } from '@ant-design/icons';
import AppointmentApi from '@app/api/appointment.api';
import { DoctorApi } from '@app/api/doctors.api';
import { AppointmentType } from '@app/api/type';
import { getAppointmentName } from '@app/services/appointment.service';
import { renderImage } from '@app/services/utils/image';
import { AppointmentStatus, statusStyle } from '@app/services/utils/status';
import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Typography,
  Upload,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import ModalRecommandation from './ModalRecommandation';
const { TextArea } = Input;

type IProps = {
  appointment: AppointmentType | undefined;
  isVisible: boolean;
  onCloseModal: () => void;
};

const ModalRdvDetail: React.FC<IProps> = ({
  appointment,
  isVisible,
  onCloseModal,
}) => {
  const [data, setData] = useState(appointment);
  const [note, setNote] = useState<string>('');
  const [timer, setTimer] = useState<any>();
  const [currentStatus, setCurrentStatus] = useState<AppointmentStatus>();
  const [recommandationModal, setRecommandationModal] =
    useState<boolean>(false);
  const [status, setStatus] = useState(
    statusStyle(currentStatus || AppointmentStatus.pending),
  );

  useEffect(() => {
    setData(appointment);
    setNote(appointment?.note || '');
    setCurrentStatus(data?.status || AppointmentStatus.pending);
  }, [appointment]);

  const date = dayjs(data?.date).format('DD.MM.YYYY HH:mm');

  const customRequest = async (options: any) => {
    const data = new FormData();
    data.append('file', options.file);
    data.append('appointmentId', (appointment?.id || '').toString());

    const res = await DoctorApi.addFileIntoAppointment(data);
    options.onSuccess('Ok');
    setData(res);
  };
  console.log(currentStatus);
  const autoSaveNote = async (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    clearInterval(timer);
    const value = event.target.value;
    setNote(value);
    const time = setTimeout(async () => {
      await AppointmentApi.editAppointment(appointment?.id || 0, {
        note: value,
      });
    }, 500);
    setTimer(time);
  };

  const changeStatus = async (newStatus: AppointmentStatus) => {
    setStatus(statusStyle(newStatus));
    setCurrentStatus(newStatus);
    const res = await AppointmentApi.editAppointment(appointment?.id || 0, {
      status: newStatus,
    });
    setData(res);
  };

  const onRecommandation = () => {
    setRecommandationModal(true);
  };

  return (
    <>
      <Modal
        open={isVisible}
        title="Rendez-vous"
        onCancel={onCloseModal}
        onOk={onCloseModal}
        width={760}
        centered
      >
        <Space size={'middle'} direction="vertical" style={{ width: '100%' }}>
          <Row gutter={{ md: 24 }}>
            <Col span={24}>
              <Row>
                <Col span={24} style={{ marginBottom: 10 }}>
                  <p
                    style={{
                      marginTop: 10,
                      textDecoration: 'underline',
                    }}
                  >
                    <strong>Information de l'utilisateur Proxidoc:</strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col span={7}>
                  <Avatar size={100} src={renderImage('')} />
                </Col>
                <Col span={17}>
                  <Typography.Title level={5}>
                    {data?.user?.firstName} {data?.user?.lastName}
                  </Typography.Title>
                  <p>Adresse: {data?.user?.address || '-'}</p>
                  <p>Téléphone: {data?.user?.phone || '-'}</p>
                  <p>Email: {data?.user?.email}</p>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Badge.Ribbon text={status.text} color={status.color}>
                <Card
                  title={
                    <Row>
                      <Col span={24}>
                        <p>
                          <strong>Rendez-vous du {date}</strong>
                        </p>
                      </Col>
                    </Row>
                  }
                  size="small"
                >
                  <p
                    style={{
                      backgroundColor: '#76D5CB',
                      color: 'white',
                      padding: 5,
                      borderRadius: 5,
                    }}
                  >
                    <PushpinOutlined />
                    {data?.isDomicile ? 'A domicile' : 'En cabinet'}
                  </p>
                  <p>Nom et prénom: {getAppointmentName(data as any)}</p>
                  <Divider />
                  <p
                    style={{
                      marginTop: 10,
                      textDecoration: 'underline',
                    }}
                  >
                    <strong>Changer de status: </strong>
                  </p>
                  <Space direction="vertical">
                    <div>
                      <Radio.Group
                        value={currentStatus}
                        onChange={(e) => changeStatus(e.target.value)}
                        optionType="button"
                        buttonStyle="solid"
                      >
                        <Radio.Button value={AppointmentStatus.pending}>
                          En Attente
                        </Radio.Button>
                        <Radio.Button value={AppointmentStatus.confirmed}>
                          Confirmer
                        </Radio.Button>
                        <Radio.Button value={AppointmentStatus.cancelled}>
                          Annuler
                        </Radio.Button>
                      </Radio.Group>
                    </div>
                  </Space>
                  <Divider />

                  <div>
                    <p
                      style={{
                        marginTop: 10,
                        textDecoration: 'underline',
                      }}
                    >
                      <strong>Recommander un autre médecin</strong>
                    </p>
                    <Button onClick={onRecommandation}>Chercher</Button>
                  </div>
                  <Divider />

                  <p
                    style={{
                      marginTop: 10,
                      textDecoration: 'underline',
                    }}
                  >
                    <strong>Notes:</strong>
                  </p>
                  <TextArea
                    showCount
                    rows={4}
                    placeholder="Ajouter votre note"
                    maxLength={1000}
                    value={note}
                    onChange={autoSaveNote}
                  />
                  <Divider />
                  <p
                    style={{
                      marginTop: 30,
                      textDecoration: 'underline',
                    }}
                  >
                    <strong>Historique de fichiers:</strong>
                  </p>
                  <Row>
                    {(data?.files || [])?.length < 1 ? (
                      <Empty
                        description={<p>Pas de fichier envoyé</p>}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    ) : (
                      data?.files?.map((file) => (
                        <Col span={8} key={file.id}>
                          <Image
                            width={150}
                            height={100}
                            src={renderImage(file.filePath)}
                            style={{ objectFit: 'cover' }}
                          />
                        </Col>
                      ))
                    )}
                  </Row>
                  <div style={{ marginTop: 15 }}>
                    <Upload
                      customRequest={customRequest}
                      showUploadList={false}
                    >
                      <Button size="small" icon={<UploadOutlined />}>
                        Ajouter un fichier
                      </Button>
                    </Upload>
                  </div>
                </Card>
              </Badge.Ribbon>
            </Col>
          </Row>
        </Space>
      </Modal>
      <ModalRecommandation
        isVisible={recommandationModal}
        setIsVisible={setRecommandationModal}
        appointment={appointment}
      />
    </>
  );
};

export default ModalRdvDetail;
