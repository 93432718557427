import { url } from '@app/services/url.service';
import { ApiReq } from './http.api';

export type NotificationType =
  | 'RDV_STATUS'
  | 'NEW_RDV'
  | 'NEW_FILE'
  | 'RECOMMANDATION'
  | 'ADMIN_CONTACT';

export type NotificationItem = {
  appointment: any;
  type: NotificationType;
  text: string;
  id: number;
  createdAt: string;
  isRead: boolean;
};

export default class NotificationApi {
  /**
   * Get all my notifications
   * @returns
   */
  static async get(): Promise<NotificationItem[]> {
    const req = await ApiReq.get(`${url.notification}`);

    return new Promise((resolve) => resolve(req?.data));
  }

  /**
   * Mark all notifiation read
   */
  static async markAllNotificationRead() {
    const req = await ApiReq.post(`${url.notification}`);

    return new Promise((resolve) => resolve(req?.data));
  }

  /**
   * Mark all notifiation read
   */
  static async markNotificationRead(id: number) {
    const req = await ApiReq.patch(`${url.notification}/${id}`);

    return new Promise((resolve) => resolve(req?.data));
  }
}
