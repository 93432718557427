import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(
  () => import('@app/components/layouts/AuthLayout/AuthLayout'),
);
import LoginPage from '@app/pages/LoginPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import DoctorRendezVousPage from '@app/pages/Doctors/DoctorRendezVous';

const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const ChoiseAbonnementPage = React.lazy(
  () => import('@app/pages/ChoiseAbonnementPage'),
);
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const PersonalInfoPage = React.lazy(
  () => import('@app/pages/PersonalInfoPage'),
);
const PersonalInfoPercuPage = React.lazy(
  () => import('@app/pages/Users/PercutInfoPersonal'),
);
const SecuritySettingsPage = React.lazy(
  () => import('@app/pages/SecuritySettingsPage'),
);
const NotificationsPage = React.lazy(
  () => import('@app/pages/NotificationsPage'),
);
const DisponibilityGestionPage = React.lazy(
  () => import('@app/pages/DisponibilityGestionPage'),
);
const PaymentsPage = React.lazy(() => import('@app/pages/PaymentsPage'));
const Logout = React.lazy(() => import('./Logout'));
const HomeAdminPage = React.lazy(() => import('@app/pages/Home/HomeAdmin'));
const RendezVousPage = React.lazy(
  () => import('@app/pages/RendezVous/RendezVous'),
);
const DashboardRendezVous = React.lazy(
  () => import('@app/pages/RendezVous/DashboardRdv/DashboardRdv'),
);
const RendezVousEditionPage = React.lazy(
  () => import('@app/pages/RendezVous/EditAppointment'),
);
const AdminDoctor = React.lazy(() => import('@app/pages/Doctors/Doctor.index'));
const UsersAdmin = React.lazy(() => import('@app/pages/Users/UsersIndexPage'));
const Speciality = React.lazy(() => import('@app/pages/data_use/speciality'));
const CategoryUser = React.lazy(
  () => import('@app/pages/data_use/CategoryUser'),
);
const Contact = React.lazy(() => import('@app/pages/contact/contact'));
const Abus = React.lazy(() => import('@app/pages/abus/abus'));
const Newsletter = React.lazy(
  () => import('@app/pages/newsletters/newsletter'),
);
const City = React.lazy(() => import('@app/pages/data_use/City'));

const DocumentProfessionnelPage = React.lazy(
  () => import('@app/pages/PatientDocument/DocumentProfessionnel'),
);
const PatientDocumentDetailPage = React.lazy(
  () => import('@app/pages/PatientDocument/PatientDocumentDetail'),
);

export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';

const HomeAdmin = withLoading(HomeAdminPage);
const RendezVous = withLoading(RendezVousPage);
const DashboardRdv = withLoading(DashboardRendezVous);

// Maps
const DoctorIndex = withLoading(AdminDoctor);
const UsersIndex = withLoading(UsersAdmin);
const SpecialityIndex = withLoading(Speciality);
const CategoryUserIndex = withLoading(CategoryUser);
const ContactIndex = withLoading(Contact);
const AbusIndex = withLoading(Abus);
const NewsletterIndex = withLoading(Newsletter);
const CityIndex = withLoading(City);

const ServerError = withLoading(ServerErrorPage);
const ChoiseAbonnement = withLoading(ChoiseAbonnementPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const PersonalInfoPercu = withLoading(PersonalInfoPercuPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);
const DisponibilityGestion = withLoading(DisponibilityGestionPage);
const Payments = withLoading(PaymentsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);
const DocumentProfessionnel = withLoading(DocumentProfessionnelPage);
const PatientDocumentDetail = withLoading(PatientDocumentDetailPage);

const AppointementDoctorList = withLoading(DoctorRendezVousPage);

// Survey
const RendezVousEdition = withLoading(RendezVousEditionPage);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/apps'} element={protectedLayout}>
          <Route index element={<HomeAdmin />} />
          <Route path="server-error" element={<ServerError />} />
          <Route path="choix-abonnement" element={<ChoiseAbonnement />} />
          <Route path="404" element={<Error404 />} />
          <Route path="mon-profile" element={<PersonalInfoPercu />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route
              path="document-professionnel"
              element={<DocumentProfessionnel />}
            />
            <Route
              path="document-professionnel/:id"
              element={<PatientDocumentDetail />}
            />
            <Route path="security-settings" element={<SecuritySettings />} />
            <Route
              path="gestion-disponibility"
              element={<DisponibilityGestion />}
            />
            <Route path="payments" element={<Payments />} />
          </Route>
          <Route path="appointment">
            <Route path="list" element={<RendezVous />} />
            <Route path="edit/:id" element={<RendezVousEdition />} />
            <Route path="dashboard-rendez-vous" element={<DashboardRdv />} />
          </Route>
          <Route path="doctor">
            <Route path="list" element={<DoctorIndex />} />
            <Route
              path="appointment/:id"
              element={<AppointementDoctorList />}
            />
          </Route>
          <Route path="data_use">
            <Route path="speciality" element={<SpecialityIndex />} />
            <Route path="category-user" element={<CategoryUserIndex />} />
            <Route path="city" element={<CityIndex />} />
          </Route>
          <Route path="contact" element={<ContactIndex />} />
          <Route path="abus" element={<AbusIndex />} />
          <Route path="newsletter" element={<NewsletterIndex />} />
          <Route path="users">
            <Route path="list" element={<UsersIndex />} />
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
};
