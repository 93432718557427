import {
  BellOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { UserRoles } from '@app/constants/roles';
import React from 'react';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  role?: UserRoles[];
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Dashboard',
    key: 'dashboard',
    url: '/apps',
    icon: <DashboardOutlined />,
    role: [UserRoles.doctor, UserRoles.superadmin],
    children: [],
  },
  {
    title: 'Mon profil',
    key: 'profil',
    url: '/apps/mon-profile',
    icon: <UserOutlined />,
    role: [UserRoles.doctor],
    children: [
      {
        title: 'Aperçu',
        key: 'mon-profile',
        url: '/apps/mon-profile',
      },
      {
        title: 'Modifier mon profil',
        key: 'modify_profil',
        url: '/apps/profile/personal-info',
      },
      {
        title: 'Paramètre de securité',
        key: 'setting_params',
        url: '/apps/profile/security-settings',
      },
      {
        title: 'Gestion de disponibilité',
        key: 'gestion_disponibility',
        url: '/apps/profile/gestion-disponibility',
      },
      {
        title: 'Paiement',
        key: 'payment',
        url: '/apps/profile/payments',
      },
    ],
  },
  {
    title: 'Dossier patient',
    key: 'dossier-patient',
    url: '/apps/profile/document-professionnel',
    icon: <BellOutlined />,
    role: [UserRoles.doctor],
    children: [],
  },
  {
    title: 'Mon profil',
    key: 'profil',
    url: '/apps/profile/personal-info',
    icon: <UserOutlined />,
    role: [UserRoles.superadmin],
    children: [],
  },
  {
    title: 'Données utiles',
    key: 'data_use',
    url: '/apps/data_use',
    icon: <DatabaseOutlined />,
    role: [UserRoles.superadmin],
    children: [
      {
        title: 'Spécialités des médecins',
        key: 'speciality',
        url: '/apps/data_use/speciality',
      },
      {
        title: "Catégories d'utilisateur",
        key: 'categories_user',
        url: '/apps/data_use/category-user',
      },
      {
        title: 'Villes',
        key: 'city',
        url: '/apps/data_use/city',
      },
    ],
  },
  {
    title: 'Rendez-vous',
    key: 'appoitment',
    url: '/apps/appointment',
    icon: <CalendarOutlined />,
    role: [UserRoles.doctor, UserRoles.superadmin],
    children: [
      // {
      //   title: 'Dashboard',
      //   key: 'klsljdf55sd',
      //   url: '/apps/appointment/dashboard-rendez-vous',
      // },
      {
        title: 'Liste des rendez-vous',
        key: 'appointment-list',
        url: '/apps/appointment/list',
      },
    ],
  },
  {
    title: 'Médecins',
    key: 'doctoro-item',
    icon: <UserOutlined />,
    role: [UserRoles.superadmin],
    children: [
      {
        title: 'Liste des médecins',
        key: 'doctor-item-list',
        url: '/apps/doctor/list',
      },
    ],
  },
  {
    title: 'Utilisateurs',
    key: 'users-item',
    icon: <UserOutlined />,
    role: [UserRoles.superadmin],
    children: [
      {
        title: 'Liste des utilisateurs',
        key: 'user-item-list',
        url: 'users/list',
      },
    ],
  },
  {
    title: 'Contacts',
    key: 'contact',
    url: 'contact',
    icon: <PhoneOutlined />,
    role: [UserRoles.superadmin],
    children: [],
  },
  {
    title: 'Newsletters',
    key: 'newsletter',
    url: 'newsletter',
    icon: <MailOutlined />,
    role: [UserRoles.superadmin],
    children: [],
  },
  {
    title: 'Signalements',
    key: 'abus',
    url: 'abus',
    icon: <ClockCircleOutlined />,
    role: [UserRoles.superadmin],
    children: [],
  },
];
