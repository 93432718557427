import { AuthAPI } from '@app/api/auth.api';
import { readUser } from '@app/services/localStorage.service';
import { useAppDispatch } from './reduxHooks';
import { setDisponibilites, setUser } from '@app/store/slices/userSlice';
import { UserRoles } from '@app/constants/roles';

const useRefresh = () => {
  const dispatch = useAppDispatch();

  const refreshUser = async () => {
    const currentUser = readUser();
    if (!currentUser) {
      return;
    }

    const token = currentUser.token;
    const data = await AuthAPI.refreshUser({ token });
    console.log('Refresh user done!', data);
    dispatch(setUser(data));

    if (data.user.role.id === UserRoles.doctor) {
      const disponibilities = data.user?.doctor?.disponibilities || [];

      dispatch(setDisponibilites(disponibilities));
    }
  };

  return { refreshUser };
};

export default useRefresh;
