import { ApiBaseURL } from '@app/api/http.api';

export enum ImageType {
  PROFIL = 'profil',
}

/**
 * Create a absolute path for rendering image
 * @param path
 */
export const renderImage = (
  path: string,
  type: ImageType = ImageType.PROFIL,
) => {
  if (!path || path === '') {
    switch (type) {
      case ImageType.PROFIL:
        return '/default_avatar.jpg';
    }
  }

  return ApiBaseURL + '/' + path;
};
