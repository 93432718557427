import React from 'react';
import { Card, Spin } from 'antd';
import AppointmentCalendar from '../RendezVous/AppointmentCalendar';
import AppointmentApi from '@app/api/appointment.api';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { DoctorApi } from '@app/api/doctors.api';

const DoctorRendezVousPage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['query'],
    queryFn: () => AppointmentApi.getAppointmentDoctor(+(params.id || '0')),
  });
  const { data: doctor, isLoading: isDoctorLoading } = useQuery({
    queryKey: ['queryDoctor'],
    queryFn: () => DoctorApi.getOne(+(params.id || '0')),
  });

  return (
    <>
      {isLoading || isDoctorLoading ? (
        <Spin />
      ) : (
        <>
          <h1>Liste des rendez-vous {doctor?.name}</h1>

          <Card id="editable-table">
            <AppointmentCalendar data={data || []} refresh={refetch} />
          </Card>
        </>
      )}
    </>
  );
};

export default DoctorRendezVousPage;
