import { NotificationType } from '@app/api/notifications.api';
import { Avatar, Space, Typography } from 'antd';
import styled, { css } from 'styled-components';

interface SpacewWrapperProps {
  type: NotificationType;
  isRead: boolean;
}

export const NotificationIcon = styled(Avatar)``;

export const Title = styled(Typography.Text)`
  font-size: 0.875rem;
  font-weight: 600;
`;

export const Description = styled(Typography.Text)`
  display: block;
  font-size: 0.875rem;
  margin-top: 10px;
`;

export const SpaceWrapper = styled(Space)<SpacewWrapperProps>`
  background-color: ${(props) =>
    !props?.isRead ? '#F7F7F7' : 'var(--background-color)'};
  padding: 5px;
  cursor: pointer;
  width: 100%;

  & span[role='img'] {
    font-size: 2rem;
  }

  width: 100%;
`;
