import { AppointmentType } from '@app/api/type';
import { getAppointmentName } from '@app/services/appointment.service';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ModalRdvDetail from './ModalRdvDetail';
import { AppointmentStatus, statusStyle } from '@app/services/utils/status';

const localizer = momentLocalizer(moment);

type IProps = {
  data: AppointmentType[];
  refresh: () => void;
};

const AppointmentCalendar: React.FC<IProps> = ({ data, refresh }) => {
  const [selectedRdv, setSelectedRdv] = useState<AppointmentType>();
  const [modalAppointment, setModalAppointment] = useState<boolean>(false);

  const onSelect = (a: any) => {
    const id = a?.id;
    const appointment = data?.find((item) => item.id === id);
    setSelectedRdv(appointment);
    setModalAppointment(true);
  };

  const onCloseModal = () => {
    setModalAppointment(false);
    refresh();
  };

  const { components } = useMemo(
    () => ({
      components: {
        eventWrapper: Event,
      },
    }),
    [],
  );

  return (
    <div>
      <Calendar
        localizer={localizer}
        components={components}
        events={data?.map((item) => ({
          id: item.id,
          title: getAppointmentName(item),
          start: moment(item.date).toDate(),
          end: moment(item.date).add(15, 'minutes').toDate(),
          status: item.status,
        }))}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 750 }}
        showMultiDayTimes
        step={15}
        messages={{
          today: "Aujourd'hui",
        }}
        scrollToTime={moment().subtract(1, 'hour').toDate()}
        onSelectEvent={onSelect}
      />
      <ModalRdvDetail
        appointment={selectedRdv}
        isVisible={modalAppointment}
        onCloseModal={onCloseModal}
      />
    </div>
  );
};

function Event(data: any) {
  const { event, style, onClick, type } = data;
  console.log(data);
  const status = statusStyle(event?.status || AppointmentStatus.pending);

  const styles = () => {
    const defaultStyle = {
      fontSize: 11,
      top: `${style?.top}%`,
      left: `${style?.xOffset}%`,
      height: `${style?.height}%`,
      width: `${style?.width}%`,
      position: 'absolute',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      overflow: 'hidden',
      columnGap: 5,
      cursor: 'pointer',
      borderRadius: 2,
    };

    if (type === 'time') {
      return { ...defaultStyle, backgroundColor: status.color };
    }

    return { backgroundColor: status.color };
  };

  return (
    <div
      onClick={onClick}
      className={type === 'date' ? 'rbc-event' : ''}
      style={styles() as any}
    >
      {type === 'time' && (
        <span style={{ fontSize: 10 }}>
          {moment(event.start).format('HH:mm')} -
          {moment(event.end).format('HH:mm')}
        </span>
      )}

      <strong>{event.title}</strong>
    </div>
  );
}

export default AppointmentCalendar;
