export const url = {
  user: {
    get: '/api/v1/users',
    delete: '/api/v1/users',
    updated: '/api/v1/users',
    updatedPassword: '/api/v1/users/password-profil',
  },
  paiement: {
    abonnement: '/api/v1/paiement/om/doctor-abonnement',
    abonnement1: '/api/v1/paiement/wave/doctor-abonnement',
    abonnement2: '/api/v1/paiement/fm/doctor-abonnement',
  },
  shop: {
    get: '/api/v1/shops',
  },
  contact: {
    get: '/api/v1/contact',
  },
  abus: {
    get: '/api/v1/reporting',
  },
  newsletter: {
    get: '/api/v1/newletters',
  },
  state: {
    rdv: '/api/v1/stats/doctor',
    user: '/api/v1/stats/admin',
    rdvMounth: '/api/v1/stats/appointment-per-month',
    userMounth: '/api/v1/stats/user-per-month',
  },
  city: {
    all: '/api/v1/cities',
  },
  login: '/api/v1/auth/email/login',
  refresh: '/api/v1/auth/refresh',
  survey: {
    get: '/api/v1/survey-question',
    response: '/api/v1/survey-response',
  },
  telmaMoi: {
    getLogs: 'api/v1/logs',
    application: 'api/v1/applications',
  },
  appointment: {
    list: '/api/v1/appointment',
    me: '/api/v1/appointment/me',
    disponibility: '/api/v1/booking-calendar/disponibility',
    upload: '/api/v1/appointment/file',
  },
  doctor: {
    list: '/api/v1/doctors',
    search: '/api/v1/doctors/search',
    recommandation: '/api/v1/doctors/recommandation',
    disponibility: '/api/v1/disponibility',
    disponibilityAdd: '/api/v1/disponibility/add',
    speciality: '/api/v1/doctors/speciality',
    createSpeciality: '/api/v1/speciality',
    patientList: '/api/v1/doctors/patient',
  },
  appsEndpoint: {
    base: '/api/v1/endpoint',
  },
  queueing: '/api/v1/checking/test-list',
  calendar: '/api/v1/booking-calendar',
  stats: '/api/stats',
  notification: '/api/v1/notifications',
};
