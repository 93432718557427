import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doSetNewPassword } from '@app/store/slices/authSlice';
import * as S from './NewPasswordForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';

interface NewPasswordFormData {
  password: string;
  confirmPassword: string;
}

const initStates = {
  password: '',
  confirmPassword: '',
};

export const NewPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const hash = searchParams.get('hash');

  const handleSubmit = (values: NewPasswordFormData) => {
    setLoading(true);
    dispatch(doSetNewPassword({ newPasswordData: values.password || 'dfrsd', hash: hash || 'erze' }))
      .unwrap()
      .then(() => {
        navigate('/auth/login');
        notificationController.success({
          message: t('newPassword.successMessage'),
          description: t('newPassword.successDescription'),
        });
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initStates}>
        {/* <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          Retour
        </Auth.BackWrapper> */}
        <Auth.FormTitle>Changer mot de passe</Auth.FormTitle>
        <S.Description>Votre nouveau mot de passe doit être différent du mot de passe précédent</S.Description>
        <Auth.FormItem
          name="password"
          label="Nouveau mot de passe"
          rules={[{ required: true, message: 'Le mot de passe est requis' }]}
        >
          <Auth.FormInputPassword />
        </Auth.FormItem>
        <Auth.FormItem
          name="confirmPassword"
          label="Confirmation mot de passe"
          dependencies={['password']}
          rules={[
            { required: true, message: 'Le mot de passe de confirmation est requis' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('common.confirmPasswordError')));
              },
            }),
          ]}
          hasFeedback
        >
          <Auth.FormInputPassword />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            Confirmer
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
