import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import frFr from 'antd/lib/locale/fr_FR';
import React, { useEffect } from 'react';
import 'typeface-lato';
import 'typeface-montserrat';
import { AppRouter } from './components/router/AppRouter';
import { useAppSelector } from './hooks/reduxHooks';
import { useAutoNightMode } from './hooks/useAutoNightMode';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import GlobalStyle from './styles/GlobalStyle';
import { themeObject } from './styles/themes/themeVariables';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/global.css';
import useRefresh from './hooks/useRefresh';
import moment from 'moment';
import 'moment/locale/fr';

const queryClient = new QueryClient();
moment.locale('fr');

const App: React.FC = () => {
  const theme = useAppSelector((state) => state.theme.theme);
  const { refreshUser } = useRefresh();
  // usePWA();

  useAutoNightMode();
  useThemeWatcher();

  useEffect(() => {
    refreshUser();
  }, []);

  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <ConfigProvider locale={frFr}>
        <QueryClientProvider client={queryClient}>
          <AppRouter />
        </QueryClientProvider>
        <GlobalStyle />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </ConfigProvider>
    </>
  );
};

export default App;
