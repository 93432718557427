import { url } from '@app/services/url.service';
import { ApiReq } from './http.api';
import { AppointmentType } from './type';

export type Booking = {
  id?: number;
  description: string;
  status: string;
  firstname: string;
  address: string;
  appointmentAddress: string;
  msisdn: string;
  date: string;
  email: string;
  msisdnContact: string;
  createdAt?: string;
  instant_booking: boolean;
  calendarId?: number;
};

export type Checkin = {
  id: number;
  code: string;
  isDone: boolean;
  booking: Booking;
  createdAt: string;
  updateAt: string;
  startTreatment?: Date;
  endTreatment?: Date;
  treatmentTime?: number;
  checkin?: Checkin;
};

export default class AppointmentApi {
  static async editAppointment(
    id: number,
    editAppointment: Partial<AppointmentType>,
  ): Promise<AppointmentType> {
    const dataToSend = { ...editAppointment };
    delete dataToSend.id;

    const res = await ApiReq.patch(url.appointment.list + '/' + id, {
      ...dataToSend,
    });

    return new Promise((resolve) => resolve(res?.data));
  }

  static async deleteAppointment(deleteAppointment: Booking) {
    const id = deleteAppointment.id;

    const res = await ApiReq.delete(url.appointment.list + '/' + id);

    return new Promise((resolve) => resolve(res?.data));
  }
  /**
   * Get all appointment list
   * @returns
   */
  static async getAll(): Promise<AppointmentType[]> {
    const req = await ApiReq.get(url.appointment.list);
    console.log('req?.data', req?.data);
    return new Promise((resolve) => resolve(req?.data));
  }
  static async getAppointmentDoctor(id: number): Promise<AppointmentType[]> {
    const req = await ApiReq.get(url.appointment.list + '/doctor/' + id);
    console.log('req?.data', req?.data);
    return new Promise((resolve) => resolve(req?.data));
  }

  static async getMe(): Promise<AppointmentType[]> {
    const req = await ApiReq.get(url.appointment.me);
    return new Promise((resolve) => resolve(req?.data));
  }

  /**
   * Get all appointment list
   * @returns
   */
  static async getOne(id: string | undefined): Promise<AppointmentType> {
    const req = await ApiReq.get(url.appointment.list + '/' + id);
    return new Promise((resolve) => resolve(req?.data));
  }

  /**
   * Get all checking list
   * @returns
   */
  static async checkinList(date: string): Promise<Checkin[]> {
    const req = await ApiReq.get(
      `${url.appointment.checkingList}?date=${date}`,
    );
    return new Promise((resolve) => resolve(req?.data));
  }

  /**
   * Get all checking list
   * @returns
   */
  static async checkinTime(checkinId: number): Promise<Checkin> {
    const req = await ApiReq.post(url.appointment.checkinTime, { checkinId });
    return new Promise((resolve) => resolve(req?.data));
  }

  /**
   * Get all checking list
   * @returns
   */
  static async checkout(appointmentId: number): Promise<Checkin> {
    const req = await ApiReq.post(url.appointment.checkout, { appointmentId });
    return new Promise((resolve) => resolve(req?.data));
  }

  /**
   * Get calendar disponibility
   * @returns
   */
  static async disponibility(data: {
    date: string;
    service: string;
  }): Promise<Array<string>> {
    const req = await ApiReq.post(url.appointment.disponibility, {
      end: data.date,
      start: data.date,
      service: data.service,
    });
    return new Promise((resolve) => resolve(req?.data));
  }

  /**
   * Get calendar disponibility
   * @returns
   */
  static async createWithCheckin(data: Booking): Promise<Checkin> {
    const req = await ApiReq.post(url.appointment.createWithCheckin, {
      ...data,
    });
    return new Promise((resolve) => resolve(req?.data));
  }

  /**
   * Get calendar disponibility
   * @returns
   */
  static async noShowRappel(checkinId: number): Promise<boolean> {
    const req = await ApiReq.post(url.appointment.noShowRappel, { checkinId });
    return new Promise((resolve) => resolve(req?.data));
  }

  /**
   * Get calendar disponibility
   * @returns
   */
  static async noShowCheckout(checkinId: number): Promise<boolean> {
    const req = await ApiReq.post(url.appointment.noShowCheckout, {
      checkinId,
    });
    return new Promise((resolve) => resolve(req?.data));
  }

  /**
   * Get calendar disponibility
   * @returns
   */
  static async getQueueingView(
    calendarId: string,
  ): Promise<{ data: Booking[]; bookingAverage: number }> {
    const req = await ApiReq.get(url.queueing + '/' + calendarId);
    return new Promise((resolve) => resolve(req?.data));
  }

  /**
   * Get calendar disponibility
   * @returns
   */
  static async getStatsRdv(): Promise<{
    data: Booking[];
    bookingAverage: number;
  }> {
    const req = await ApiReq.get(url.stats);
    return new Promise((resolve) => resolve(req?.data));
  }
}
