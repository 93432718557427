import axios from 'axios';
import { UserLogin } from './auth.api';
import { Modal } from 'antd';

export const ApiReq = axios.create({
  baseURL: 'https://api.proxidoc.sn',
  // baseURL: 'http://localhost:4003',
});

export const ApiBaseURL = 'https://api.proxidoc.sn';
// export const ApiBaseURL = 'http://localhost:4003';

ApiReq.interceptors.request.use(
  (config: any) => {
    const user = localStorage.getItem('user');
    if (user) {
      const json: UserLogin = JSON.parse(user);

      config.headers.Authorization = `Bearer ${json?.token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

const handleReconnect = () => {
  localStorage.removeItem('user');
  window.location.href = '/auth/login';
};

let isModalDisplayed = false;

ApiReq.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401 && !isModalDisplayed) {
      isModalDisplayed = true;
      Modal.error({
        title: 'Connexion expirée',
        content: 'Votre session est expirée. Veuillez vous reconnecter.',
        okText: 'Reconnecter',
        onOk: () => handleReconnect(),
      });
    }
    return Promise.reject(error);
  },
);

export interface ApiErrorData {
  message: string;
}
