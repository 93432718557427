import { H4 } from '@app/components/common/typography/H4/H4';
import { Button, Col, Row } from 'antd';
import React from 'react';
import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import * as S from '../Header.styles';
import '../../../assets/css/global.css';
import { Link } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({
  isTwoColumnsLayout,
}) => {
  const user = useAppSelector((state) => state.user.user);
  return (
    <Row justify="space-between" align="middle">
      <Col lg={4} xxl={3}></Col>

      <S.ProfileColumn
        className="pr-0"
        xl={20}
        xxl={21}
        $isTwoColumnsLayout={isTwoColumnsLayout}
      >
        <Row align="middle" justify="end" gutter={[10, 10]}>
          {user?.user?.role?.id === 3 && (
            <Col>
              <Link to="/apps/choix-abonnement">
                <Button type="primary" size="small">
                  Choisir votre type d&apos;abonnement
                </Button>
              </Link>
            </Col>
          )}
          <Col>
            <Row gutter={[{ xxl: 10 }, { xxl: 10 }]}>
              <Col>
                <NotificationsDropdown />
              </Col>
              <Col>
                <SettingsDropdown />
              </Col>
            </Row>
          </Col>

          <Col>
            <ProfileDropdown />
          </Col>
        </Row>
      </S.ProfileColumn>
    </Row>
  );
};
