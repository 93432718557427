import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Badge, Button, Upload, UploadFile, UploadProps } from 'antd';
import * as S from './ProfileInfo.styles';
import { UserLogin } from '@app/api/auth.api';
import {
  EditOutlined,
  RocketOutlined,
  UploadOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { ApiBaseURL } from '@app/api/http.api';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { setUser } from '@app/store/slices/userSlice';
import { toast } from 'react-toastify';

interface ProfileInfoProps {
  profileData: UserLogin | null;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ profileData }) => {
  const [fullness] = useState(90);
  const dispatch = useAppDispatch();

  const uploadRef = useRef<any>(null);

  const [fileList, setFileList] = useState<UploadFile[]>(null);

  const [loadingChange, setLoadingChange] = useState(false);

  const user = useAppSelector((state) => state.user.user);

  const uploadNewPdp = () => {
    document.getElementById('upload-pdp')?.click();
  };
  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setLoadingChange(true);
  };

  const onSuccess = () => {
    toast.success('Rendez-vous modifié avec succès');
  };

  const onError = () => {
    toast.error("Une erreur s'est produite");
  };

  const customUploadRequest = async ({ file, onSuccess, onError }) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      // Ajoutez les données supplémentaires si nécessaire, par exemple :
      // formData.append('id', user?.user?.doctor?.id);

      const response = await fetch(
        ApiBaseURL + '/api/v1/doctors/photo-profil',
        {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + user?.token,
          },
          body: formData,
        },
      );

      if (response.ok) {
        const data = await response.json();
        // Gérez la réponse de l'API ici
        const newUser = {
          token: user?.token,
          user: {
            ...user?.user,
            doctor: data,
          },
        };
        dispatch(setUser(newUser));
        setLoadingChange(false);
        onSuccess(data);
      } else {
        // Gérez les erreurs de requête ici
        console.error("Erreur lors de l'envoi du fichier");
        onError(new Error("Erreur lors de l'envoi du fichier"));
      }
    } catch (error) {
      // Gérez les erreurs d'exception ici
      console.error("Erreur lors de l'envoi du fichier", error);
      onError(error);
    }
  };

  return profileData ? (
    <S.Wrapper>
      <S.ImgWrapper>
        <Badge
          offset={[-10, 35]}
          count={
            profileData?.user?.role?.id === 3 ? (
              <Button
                type="primary"
                size="small"
                onClick={uploadNewPdp}
                shape="circle"
              >
                <EditOutlined />
              </Button>
            ) : (
              <Button type="primary" danger size="small" shape="circle">
                <UserSwitchOutlined />
              </Button>
            )
          }
        >
          <Avatar
            className={loadingChange ? 'loading' : ''}
            shape="circle"
            style={{ width: '100%', height: '100%' }}
            src={
              profileData?.user?.doctor?.photoProfil
                ? ApiBaseURL + '/' + profileData?.user?.doctor?.photoProfil
                : 'https://oasys.ch/wp-content/uploads/2019/03/photo-avatar-profil.png'
            }
            alt="Profile"
          />
        </Badge>
      </S.ImgWrapper>
      <S.Title>
        {profileData?.user?.role?.id === 3
          ? `${profileData?.user?.doctor?.name}`
          : `${profileData?.user?.firstName} ${profileData?.user?.lastName[0]}`}
      </S.Title>
      <S.Subtitle>
        {profileData?.user?.role?.name === 'Doctor' ? 'Médecin' : 'Super admin'}
      </S.Subtitle>
      {/* <S.FullnessWrapper>
        <S.FullnessLine width={fullness}>{fullness}%</S.FullnessLine>
      </S.FullnessWrapper> */}
      <ImgCrop
        modalTitle="Ajouter un nouveau photo de profile"
        modalOk="Enregister"
        modalCancel="Annuler"
        rotationSlider
      >
        <Upload
          ref={uploadRef}
          action={ApiBaseURL + '/api/v1/doctors/photo-profil'}
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          accept="image/*,.pdf,application/pdf"
          headers={{
            Authorization: 'Bearer ' + user?.token,
          }}
          className="upload-pdp"
          data={{
            id: user?.user?.doctor?.id,
          }}
          customRequest={customUploadRequest} // Ajouter cette ligne
        >
          <Button id="upload-pdp" icon={<UploadOutlined />}>
            Upload
          </Button>
        </Upload>
      </ImgCrop>
    </S.Wrapper>
  ) : null;
};
