import NotificationApi, { NotificationItem } from '@app/api/notifications.api';
import { Col, Row, Space } from 'antd';
import { Notification } from 'components/common/Notification/Notification';
import React, { useMemo } from 'react';
import * as S from './NotificationsOverlay.styles';
import { Link, useNavigate } from 'react-router-dom';

interface NotificationsOverlayProps {
  notifications: NotificationItem[];
}

export const NotificationsOverlay: React.FC<NotificationsOverlayProps> = ({
  notifications,
  ...props
}) => {
  const navigate = useNavigate();

  const noticesList = useMemo(
    () =>
      notifications.slice(0, 4).map((notification, index) => {
        return (
          <Notification
            key={index}
            item={notification}
            onClick={() => navigate('/apps/notifications')}
          />
        );
      }),
    [notifications],
  );

  const markAllRead = () => {
    NotificationApi.markAllNotificationRead();
  };

  return (
    <S.NoticesOverlayMenu mode="inline" {...props}>
      <S.MenuRow gutter={[20, 20]}>
        <Col style={{ minWidth: 320 }} span={24}>
          {notifications.length > 0 ? (
            <Space direction="vertical" size={10} split={<S.SplitDivider />}>
              {noticesList}
            </Space>
          ) : (
            <S.Text>Vous n'avez pas de notifications</S.Text>
          )}
        </Col>
        <Col span={24}>
          <Row gutter={[10, 10]}>
            {notifications.length > 0 && (
              <Col span={24}>
                <S.Btn size="small" type="ghost" onClick={markAllRead}>
                  Tout marqué comme lu
                </S.Btn>
              </Col>
            )}
            <Col span={24}>
              <S.Btn type="link">
                <Link to="/apps/notifications">Tout voir</Link>
              </S.Btn>
            </Col>
          </Row>
        </Col>
      </S.MenuRow>
    </S.NoticesOverlayMenu>
  );
};
