import { ApiReq } from '@app/api/http.api';
import { url } from '@app/services/url.service';
import { UserType } from './type';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface UserLogin {
  token: string;
  user: UserType;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  user: UserType;
}

export class AuthAPI {
  static async login(payload: LoginRequest): Promise<UserLogin> {
    const res = await ApiReq.post(url.login, { ...payload });
    console.log('res', res?.data);

    return new Promise((resolve) => {
      resolve(res?.data);
    });
  }

  static async refreshUser(payload: { token: string }): Promise<UserLogin> {
    const res = await ApiReq.post(url.refresh, { ...payload });
    console.log('res', res?.data);

    return new Promise((resolve) => {
      resolve(res?.data);
    });
  }
}

export const resetPassword = (
  resetPasswordPayload: ResetPasswordRequest,
): Promise<undefined> =>
  ApiReq.post<undefined>('api/v1/auth/forgot/password', {
    ...resetPasswordPayload,
  }).then(({ data }) => data);

export const verifySecurityCode = (
  securityCodePayload: SecurityCodePayload,
) => {
  return;
};

export const setNewPassword = (data: {
  newPasswordData: string;
  hash: string;
}): Promise<undefined> =>
  ApiReq.post<undefined>('api/v1/auth/reset/password', {
    password: data.newPasswordData,
    hash: data.hash,
  }).then(({ data }) => data);
