/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doLogin } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import { ReactComponent as FacebookIcon } from '@app/assets/icons/facebook.svg';
import { ReactComponent as GoogleIcon } from '@app/assets/icons/google.svg';
import * as S from './LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useMutation } from '@tanstack/react-query';
import { AuthAPI, UserLogin } from '@app/api/auth.api';
import { setUser } from '@app/store/slices/userSlice';
import { toast } from 'react-toastify';
import { Form } from 'antd';

interface LoginFormData {
  email: string;
  password: string;
}

export const initValues: LoginFormData = {
  email: '',
  password: '',
};

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const mutation = useMutation({ mutationFn: AuthAPI.login });

  const onSuccess = (data: UserLogin) => {
    dispatch(setUser(data));

    toast.success('Connexion réussie');
    navigate('/apps');
  };

  const onError = () => {
    toast.error('Identifiant invalide');
  };

  const handleSubmit = async (values: LoginFormData) => {
    await mutation.mutate({ ...values }, { onSuccess, onError });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm
        onFinish={handleSubmit}
        requiredMark="optional"
        initialValues={initValues}
      >
        <Auth.FormTitle>Proxidoc</Auth.FormTitle>
        <Auth.FormItem
          name="email"
          label={'Email'}
          rules={[
            { required: true, message: "L'email est requis" },
            {
              type: 'email',
              message: 'Email non valide',
            },
          ]}
        >
          <Auth.FormInput placeholder={'Votre adresse email'} />
        </Auth.FormItem>
        <Auth.FormItem
          label="Mot de passe"
          name="password"
          rules={[{ required: true, message: '' }]}
        >
          <Auth.FormInputPassword placeholder={'Votre password'} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <S.RememberMeText>Se souvenir de moi</S.RememberMeText>
            </Auth.FormCheckbox>
          </BaseForm.Item>
          <Link to="/auth/forgot-password">
            <S.ForgotPasswordText>
              {t('Mot de passe oublié ?')}
            </S.ForgotPasswordText>
          </Link>
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton
            type="primary"
            htmlType="submit"
            loading={mutation?.isLoading}
          >
            Se connecter
          </Auth.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
