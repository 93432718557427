import React from 'react';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { H6 } from '@app/components/common/typography/H6/H6';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import { Avatar, Col, Row } from 'antd';
import { ApiBaseURL } from '@app/api/http.api';

export const ProfileDropdown: React.FC = () => {
  const { isTablet } = useResponsive();

  const user = useAppSelector((state) => state.user.user);

  return user ? (
    <Dropdown overlay={<ProfileOverlay />} trigger={['click']}>
      <S.ProfileDropdownHeader as={Row} gutter={[10, 10]} align="middle">
        <Col>
          <Avatar
            src={user?.user?.doctor?.photoProfil ?(ApiBaseURL+'/'+user?.user?.doctor?.photoProfil) : "https://oasys.ch/wp-content/uploads/2019/03/photo-avatar-profil.png"}
            alt="User"
            shape="circle"
            size={40}
          />
        </Col>
        {isTablet && (
          <Col>
            <H6>{user?.user?.role?.id===3 ? `${user?.user?.doctor?.name}` : `${user?.user?.firstName} ${user?.user?.lastName[0]}`}</H6>
          </Col>
        )} 
      </S.ProfileDropdownHeader>
    </Dropdown>
  ) : null;
}