import React from 'react';
import { useTranslation } from 'react-i18next';
import { profileNavData } from '@app/constants/profileNavData';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './ProfileNav.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { profileNavDataDoctor } from '@app/constants/profileNavDataDoctor';
import { UserRoles } from '@app/constants/roles';

export const ProfileNav: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector((state) => state.user.user);

  return (
    <S.Wrapper>
      {user?.user?.role?.id === UserRoles.superadmin &&
        profileNavData.map((item) => (
          <S.Btn
            key={item.id}
            icon={item.icon}
            type="text"
            color={item.color}
            onClick={() => navigate(item.href)}
            isActive={`/profile/${item.href}` === location.pathname}
          >
            {t(item.name)}
          </S.Btn>
        ))}
    </S.Wrapper>
  );
};
