import { DoctorCreateType } from '@app/pages/Doctors/type';
import { url } from '@app/services/url.service';
import { ApiReq } from './http.api';
import { AppointmentType, DoctorType, PatientDataType, UserType } from './type';

export class DoctorApi {
  static async getAll(): Promise<DoctorType[]> {
    const req = await ApiReq.get(url.doctor.list);
    console.log('req?.data', req?.data);
    return new Promise((resolve) => resolve(req?.data));
  }

  static async getAllSpeciality(): Promise<any> {
    const req = await ApiReq.get(url.doctor.speciality);
    console.log('req?.data', req?.data);
    return new Promise((resolve) => resolve(req?.data));
  }

  static async createSpeciality(name: string): Promise<any> {
    const req = await ApiReq.post(url.doctor.createSpeciality, name);
    console.log('req?.data', req?.data);
    return new Promise((resolve) => resolve(req?.data));
  }

  static async patchSpeciality(data: {
    name: string;
    id: number;
    isActive: boolean;
  }): Promise<any> {
    const req = await ApiReq.patch(
      url.doctor.createSpeciality + '/' + data.id,
      data,
    );
    console.log('req?.data', req?.data);
    return new Promise((resolve) => resolve(req?.data));
  }

  static async createNewDocs(data: DoctorCreateType): Promise<DoctorType[]> {
    const req = await ApiReq.post(url.doctor.list, { ...data });

    return new Promise((resolve) => resolve(req?.data));
  }

  static async getOne(id: number): Promise<DoctorType> {
    const req = await ApiReq.get(`${url.doctor.list}/${id}`);
    console.log('req?.data', req?.data);
    return new Promise((resolve) => resolve(req?.data));
  }

  static async edit(data: {
    id: number;
    data: Partial<DoctorCreateType>;
  }): Promise<DoctorType> {
    const req = await ApiReq.patch(`${url.doctor.list}/${data.id}`, {
      ...data.data,
    });

    return new Promise((resolve) => resolve(req?.data));
  }

  static async disponibility(data: {
    doctorId: number;
    date: string;
  }): Promise<DoctorType> {
    const req = await ApiReq.post(`${url.doctor.disponibility}`, { ...data });

    return new Promise((resolve) => resolve(req?.data));
  }

  static async activation(data: {
    doctorId: number;
    isActive: boolean;
  }): Promise<DoctorType> {
    const req = await ApiReq.post(`${url.doctor.list}/activation`, data);

    return new Promise((resolve) => resolve(req?.data));
  }

  static async patchDisponibility(data: any): Promise<DoctorType> {
    const req = await ApiReq.post(`${url.doctor.disponibilityAdd}`, {
      ...data,
    });

    return new Promise((resolve) => resolve(req?.data));
  }

  static async getFile(): Promise<DoctorType> {
    const req = await ApiReq.get(`${url.doctor.list}/file`);
    return new Promise((resolve) => resolve(req?.data));
  }
  static async getFilePatient(): Promise<DoctorType> {
    const req = await ApiReq.get(`${url.doctor.list}/sended-file`);
    return new Promise((resolve) => resolve(req?.data));
  }

  /**
   * Fetch all pateint for one doctor
   * @returns
   */
  static async fetchPatientData(): Promise<UserType[]> {
    const req = await ApiReq.post(url.doctor.patientList);

    return new Promise((resolve) => resolve(req?.data));
  }

  /**
   * Fetch all pateint for one doctor
   * @returns
   */
  static async fetchOnePatientData(
    id: string | undefined,
  ): Promise<PatientDataType | null> {
    if (!id) {
      return null;
    }
    const req = await ApiReq.get(`${url.doctor.patientList}/${id}`);

    return new Promise((resolve) => resolve(req?.data));
  }

  static async addFileIntoAppointment(
    file: FormData,
  ): Promise<AppointmentType> {
    const req = await ApiReq.post(`${url.appointment.upload}`, file);

    return new Promise((resolve) => resolve(req?.data));
  }

  static async search(q: string): Promise<DoctorType[]> {
    const req = await ApiReq.post(`${url.doctor.search}`, {
      q,
      proximity: false,
      distance: 10,
    });

    return new Promise((resolve) => resolve(req?.data));
  }

  static async sendRecommandation(data: {
    doctorId: number;
    appointmentId: number;
  }): Promise<DoctorType[]> {
    const req = await ApiReq.post(`${url.doctor.recommandation}`, {
      ...data,
    });

    return new Promise((resolve) => resolve(req?.data));
  }
}
