export enum AppointmentStatus {
  'pending' = 'pending',
  'confirmed' = 'confirmed',
  'cancelled' = 'cancelled',
}

export function statusStyle(status: AppointmentStatus) {
  const data = {
    [AppointmentStatus.pending]: {
      color: '#faad14',
      text: 'En attente',
    },
    [AppointmentStatus.confirmed]: { color: '#76d5c6', text: 'Valider' },
    [AppointmentStatus.cancelled]: { color: '#f5222d', text: 'Annuler' },
  };

  return data[status] ?? data?.[AppointmentStatus.pending];
}
