import {
  BorderlessTableOutlined,
  BulbOutlined,
  CalendarOutlined,
  FileAddFilled,
  UserAddOutlined,
} from '@ant-design/icons';
import { NotificationItem, NotificationType } from '@app/api/notifications.api';
import { Badge } from 'antd';
import moment from 'moment';
import React, { ReactNode } from 'react';
import * as S from './Notification.styles';

interface NotificationProps {
  item: NotificationItem;
  onClick: () => void;
}

export const Notification: React.FC<NotificationProps> = ({
  item: { createdAt, isRead, text, type },
  onClick,
}) => {
  const icons: Record<NotificationType, ReactNode> = {
    RDV_STATUS: <CalendarOutlined />,
    NEW_RDV: <UserAddOutlined />,
    NEW_FILE: <FileAddFilled />,
    RECOMMANDATION: <BorderlessTableOutlined />,
    ADMIN_CONTACT: <BulbOutlined />,
  };

  const icon = icons[type] || icons.RDV_STATUS;

  return (
    <div onClick={onClick}>
      <S.SpaceWrapper type={type} align="start" size="middle" isRead={isRead}>
        {icon}
        <Badge dot={!isRead}>
          <S.Title>{text}</S.Title>
          <S.Description>
            {moment(createdAt).format('DD.MM.YYYY HH:mm')}
          </S.Description>
        </Badge>
      </S.SpaceWrapper>
    </div>
  );
};
