import React from 'react';
import * as S from './MainSider/MainSider.styles';
import { RightOutlined } from '@ant-design/icons';
import { useResponsive } from 'hooks/useResponsive';
import logo from 'assets/logo-transparent.png';
import logoDark from 'assets/digital-logo-black.svg';
import { useAppSelector } from '@app/hooks/reduxHooks';
import '../../../../assets/css/global.css';

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({
  isSiderCollapsed,
  toggleSider,
}) => {
  const { tabletOnly } = useResponsive();

  const theme = useAppSelector((state) => state.theme.theme);

  const img = theme === 'dark' ? logo : logo;

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to="/apps">
        <img
          src={logo}
          alt="Proxidoc"
          className="logo-global"
          style={{ objectFit: 'contain' }}
        />
      </S.SiderLogoLink>
      {tabletOnly && (
        <S.CollapseButton
          shape="circle"
          size="small"
          $isCollapsed={isSiderCollapsed}
          icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
          onClick={toggleSider}
        />
      )}
    </S.SiderLogoDiv>
  );
};
