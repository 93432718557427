import React, { useEffect, useState } from 'react';
import { Header } from '../../../header/Header';
import MainSider from '../sider/MainSider/MainSider';
import MainContent from '../MainContent/MainContent';
import { MainHeader } from '../MainHeader/MainHeader';
import * as S from './MainLayout.styles';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { MEDICAL_DASHBOARD_PATH } from '@app/components/router/AppRouter';
import { useResponsive } from '@app/hooks/useResponsive';
import { useAppSelector } from '@app/hooks/reduxHooks';
import moment from 'moment';
import { Modal } from 'antd';

const MainLayout: React.FC = () => {
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const { isDesktop } = useResponsive();
  const location = useLocation();

  const toggleSider = () => setSiderCollapsed(!siderCollapsed);

  const user = useAppSelector((state) => state.user.user);
  const [inscriptionNoFinaled, setInscriptionNoFinaled] = useState(false);

  useEffect(() => {
    const isInscriptionNoFinaled =
      (user?.user?.doctor?.atHome &&
        ((user?.user?.doctor?.priceLocalFirstAppointment &&
          user?.user?.doctor?.priceLocalFirstAppointment !== 0) ||
          (user?.user?.doctor?.priceLocalAppointment &&
            user?.user?.doctor?.priceLocalAppointment !== 0))) ||
      (user?.user?.doctor?.atLocal &&
        ((user?.user?.doctor?.priceHomeFirstAppointment &&
          user?.user?.doctor?.priceHomeFirstAppointment !== 0) ||
          (user?.user?.doctor?.priceHomeAppointment &&
            user?.user?.doctor?.priceHomeAppointment !== 0)))
        ? false
        : true;

    setInscriptionNoFinaled(
      !user?.user?.doctor?.isActive ? false : isInscriptionNoFinaled,
    );
  }, [user]);

  const currentStatus = user?.user?.doctor?.currentAbonnement?.currentStatus;
  const endSubscriptionDate = user?.user?.doctor?.currentAbonnement?.data?.end;

  let countdown = '';

  if (currentStatus === 'MONTHLY' && endSubscriptionDate) {
    const endDate = moment(endSubscriptionDate);
    const today = moment();
    const duration = moment.duration(endDate.diff(today));
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();

    countdown = `${days}jours ${hours}heures ${minutes}minutes`;
  }

  useEffect(() => {
    setIsTwoColumnsLayout(
      [MEDICAL_DASHBOARD_PATH, '/apps'].includes(location.pathname) &&
        isDesktop,
    );
  }, [location.pathname, isDesktop]);

  return (
    <S.LayoutMaster>
      {user?.user?.doctor && (
        <>
          <Link to="/apps/choix-abonnement">
            <div className="float-type-abonnement">
              {user?.user?.doctor?.currentAbonnement?.currentStatus ===
              'MONTHLY'
                ? 'ABONNEMENT MENSUEL : ' + countdown
                : 'ABONNEMENT PAR COMMISSION'}
            </div>
          </Link>
          <Modal closable={false} footer={null} visible={inscriptionNoFinaled}>
            <div className="modal-content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 m-h-20 bg-img rounded-left">
                    <img
                      src="https://images.unsplash.com/photo-1525097596740-cb2a70e07e3b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80"
                      alt=""
                    />
                  </div>
                  <div className="col-md-12 text-center py-5 px-sm-5 ">
                    <h2>Terminer votre inscription</h2>
                    <p className="text-muted">
                      Finalisez votre inscription pour profiter de toutes les
                      avantages offerts sur notre plateform.
                    </p>
                    {/* <Link to="profile/personal-info"> */}
                    <Link to="#">
                      <button
                        type="button"
                        className="btn btn-cstm-dark btn-block btn-cta"
                        onClick={() => setInscriptionNoFinaled(false)}
                      >
                        Finaliser l&apos;inscription
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
      <MainSider
        isCollapsed={siderCollapsed}
        setCollapsed={setSiderCollapsed}
      />
      <S.LayoutMain>
        <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <Header
            toggleSider={toggleSider}
            isSiderOpened={!siderCollapsed}
            isTwoColumnsLayout={isTwoColumnsLayout}
          />
        </MainHeader>
        <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <div>
            <Outlet />
          </div>
          {/* {!isTwoColumnsLayout && <References />} */}
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default MainLayout;
